<template>
  <v-dialog :max-width="370" v-model="show">
    <template v-slot:activator="{on}">
      <v-btn :text="btnText" color="primary" v-on="on" @click="open">{{ activatorName }}</v-btn>
    </template>
    <v-form :valid="valid" @submit.prevent="save" @keyup.enter="save" ref="form" lazy-validation>
      <v-card>
        <v-card-title class="py-6">
          <h6 class="text-h6 mx-auto">{{ title }}</h6>
        </v-card-title>
        <v-card-text>
          <slot />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn elevation="0" @click="close">Anuluj</v-btn>
          <v-btn color="primary" type="submit" :loading="pending">Dodaj</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    btnText: {
      type: Boolean,
      required: false,
    },
    activatorName: {
      type: String,
      required: true,
    },
    pending: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      valid: true,
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
      this.$refs.form.resetValidation()
      this.$emit('closedModal')
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('formSubmitted')
      }
    },
  },
}
</script>

<style></style>
