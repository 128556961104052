<template>
  <v-card>
    <v-card-title>
      <DefaultModal
        @closedModal="clearForm"
        ref="modal"
        activator-name="Dodaj"
        title="Dodawanie do czarnej listy"
        @formSubmitted="addToBlacklist"
      >
        <AllCategoriesAutocomplete hide-details v-model="form.categoryId" />
        <v-switch v-model="form.blackListedConstantly" label="Dodaj na stałe" />
        <DatePickerInput
          label="Ważność do"
          readonly
          v-model="form.toDate"
          v-if="!form.blackListedConstantly"
        />
      </DefaultModal>
      <v-spacer />
      <v-text-field
        v-model="search"
        class="shrink"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        single-line
        hide-details
        @keyup.enter="fetchItems"
        @blur="fetchItems"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="categoriesBlackList"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:[`item.category`]="{item}">
        {{ item.category.name }}
      </template>
      <template v-slot:[`item.toDate`]="{item}">
        {{ item.toDate | date }}
      </template>
      <template v-slot:[`item.delete`]="{item}">
        <v-icon @click="removeFromBL(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <Confirm ref="modalConfirm" />
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import DefaultModal from '@/components/Modals/DefaultModal'
import AllCategoriesAutocomplete from '@/components/UI/Autocompletes/AllCategoriesAutocomplete'
import DatePickerInput from '@/components/UI/DatePickerInput'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import Confirm from '@/components/UI/Confirm'

const BlacklistRepository = RepositoryFactory.get('blackList')

export default {
  components: {
    Confirm,
    DatePickerInput,
    AllCategoriesAutocomplete,
    DefaultModal,
  },
  mixins: [paginationMixin],
  data() {
    return {
      search: '',
      form: {
        categoryId: null,
        blackListedConstantly: false,
        toDate: new Date().toISOString().slice(0, 10),
      },
      headers: [
        {text: 'Nazwa kategorii', value: 'category', width: '80%'},
        {text: 'Ważne do', value: 'toDate'},
        {text: 'Usuń', value: 'delete', align: 'center', sortable: false, width: '7%'},
      ],
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['getCategoryBlackList', 'showNotification']),
    async fetchItems() {
      this.$data.$_paginationMixin_fetchingItems = true
      const query = this.$_paginationMixin_createQuery({
        ...this.$data.$_paginationMixin_options,
        search: this.search,
      })
      this.$data.$_paginationMixin_totalCount = await this.getCategoryBlackList(query)
      this.$data.$_paginationMixin_fetchingItems = false
    },
    clearForm() {
      this.form.categoryId = null
      this.form.blackListedConstantly = false
      this.form.toDate = new Date().toISOString().slice(0, 10)
    },
    async addToBlacklist() {
      try {
        await BlacklistRepository.addToBlackList('category', {
          ...this.form,
          toDate: this.form.blackListedConstantly ? '0001-01-01' : this.form.toDate,
        })
        await this.fetchItems()
        this.showNotification({
          message: 'NOTY_BLACKLIST_CATEGORY_ADDED',
          type: 'success',
        })
        this.$refs.modal.close()
        this.form.blackListedConstantly = false
      } catch (e) {
        this.showNotification({
          message: e,
          type: 'error',
        })
      }
    },
    async removeFromBL(item) {
      const {id, category} = item
      const res = await this.$refs.modalConfirm.open({
        title: 'Usuwanie kategorii',
        message: `Czy na pewno chcesz usunąć kategorię "${category.name}"?`,
      })
      if (res) {
        try {
          await BlacklistRepository.removeFromBlackList('category', {
            blacklistCategoryId: id,
          })
          await this.fetchItems()
          this.showNotification({
            message: 'NOTY_BLACKLIST_CATEGORY_REMOVED',
            type: 'success',
          })
        } catch (e) {
          this.showNotification({
            message: e,
            type: 'error',
          })
        }
      }
    },
  },
  computed: {
    ...mapState({
      categoriesBlackList: state => state.blackLists.categoryBlackList,
    }),
    searchOptions() {
      return this.$data.$_paginationMixin_options
    },
  },
}
</script>

<style></style>
