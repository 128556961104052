<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{on}">
      <v-text-field
        outlined
        dense
        hide-details
        autocomplete="off"
        v-bind="[$attrs]"
        v-on="on"
        :class="$attrs.className"
        :value="dateFormatted"
      >
      </v-text-field>
    </template>
    <v-date-picker
      :show-week="showWeek"
      show-adjacent-months
      :max="showWeek ? maxDate : null"
      locale="pl-PL"
      first-day-of-week="1"
      :range="$attrs.range"
      :value="value"
      no-title
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import dateFilter from '@/filters/date.filter'
import dayjs from '@/utils/dayjs'

export default {
  props: {
    allowedDates: {
      type: Function,
      required: false,
    },
    value: {
      type: [String, Array],
      required: false,
    },
    showWeek: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    // readonly: {
    //   type: Boolean,
    //   default: () => false
    // }
  },
  name: 'DatePickerInput',
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    maxDate() {
      const year = new Date().getFullYear()
      return `${year - 1}-12-31`
    },
    dateFormatted() {
      let result
      let week
      let year
      // eslint-disable-next-line default-case
      switch (typeof this.value) {
        case 'undefined':
          result = ''
          break
        case 'string':
          result = dateFilter(this.value)
          break
        case 'object':
          result = this.value.map(itm => dateFilter(itm)).join(' - ')
      }
      if (this.showWeek) {
        const date = dayjs(result.slice(0, 10), 'DD.MM.YYYY')
        week = date.week() - 1
        year = date.year()
        return `Tydzień ${week}, ${year}`
      }
      return result
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style scoped></style>
